function checkout() {
  $(document).on('click', '.view-terms a', function() {
    $('#checkout-terms-and-conditions').toggle();
    return false;
  });

  $(document).on('click', '#place-order', function() {
    const agree = document.getElementById('agree');
    if (!agree.checked) {
      alert('You must accept Keeptheheaton.com\'s terms and conditions to proceed.');
      return false;
    }
  });
}

export { checkout };
