function spam() {
  var $noAjax = $('.no_ajax');
  if ($noAjax.length) {
    $noAjax.hide();
    $currentTime = $('#current_time');
    $currentTime.load($currentTime.data('url'));
  }
}

export { spam };
