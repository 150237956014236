import { token } from './helpers/token';
import debounce from './helpers/debounce';

export const searchFilter = () => {
  const search = document.querySelector('#searchFilter')

  const searching = () => {
    const container = document.getElementById('searchFilterResults');
    container.innerHTML = '<p>Searching...</p>';
  };

  const postFilterSearch = async (query) => {
    searching();
    const url = '/appliances/filter_search';
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': token(),
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        query,
      })
    };

    try {
      return await fetch(url, options);
    } catch (e) {
      return Promise.reject();
    }
  };

  let lastQuery = '';

  const doSearch = (query) => {
    if (lastQuery === query) {
      return;
    }
    lastQuery = query;

    postFilterSearch(query)
    .then(response => response.json())
    .then(({ results }) => {
      const container = document.querySelector('#searchFilterResults');
      const manualsContainer = document.querySelector('#searchFilterManualsResults')

      if (results.length) {
        container.innerHTML = '';
        manualsContainer.innerHTML = '';

        results.forEach((result) => {
          const a = document.createElement('a');
          a.setAttribute('href', result.path);

          if (result.boiler_manuals?.length) {
            const div = document.createElement('div')
            div.className = 'appliance filterable'
            manualsContainer.appendChild(div);

            const innerDiv = document.createElement('div');
            innerDiv.className = 'appliance-link-container';
            div.appendChild(innerDiv);

            innerDiv.appendChild(a);

            const applianceName = document.createElement('div');
            applianceName.className = 'appliance-name';
            applianceName.innerHTML = result.name;
            a.appendChild(applianceName);

            const boilerManualsLinks = document.createElement('div')
            boilerManualsLinks.className = 'boiler-manual-links'
            innerDiv.appendChild(boilerManualsLinks)

            result.boiler_manuals.forEach((boilerManual) => {
              const boilerManualLink = document.createElement('a');
              boilerManualLink.setAttribute('href', boilerManual.path);
              boilerManualLink.innerHTML = boilerManual.filename;
              boilerManualLink.className = 'btn primary'
              boilerManualsLinks.appendChild(boilerManualLink);
            });
          } else {
            a.innerHTML = result.name;
            a.className = 'child-page'
            container.appendChild(a);
          }
        });
      } else {
        const noResultsHTML = '<p>No results.</p>';
        container.innerHTML = noResultsHTML;
        manualsContainer.innerHTML = noResultsHTML;
      }
    });
  };

  const timeout = 1000;
  if (search) {
    search.addEventListener('change', debounce((event) => doSearch(event.target.value), timeout));
    search.addEventListener('keyup', debounce((event) => doSearch(event.target.value), timeout));
    search.addEventListener('input', debounce((event) => doSearch(event.target.value), timeout));
  }
}
