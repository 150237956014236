function basket() {
  $('.destination').change(function() {
    $('.shipping-classes').hide();
    var toShow = '#shipping-classes-' + $(this).val();
    $(toShow).show();
    $(toShow + ' input').first().click();
  });

  $('.shipping-class').change(function() {
    var shippingAmount = parseFloat($(this).data('shipping-amount'));
    var $basketTotal = $('#basket-total');
    var basketTotalExVat = parseFloat($basketTotal.data('ex-vat'));
    var vatRate = parseFloat($basketTotal.data('vat-rate'));
    var vat = (basketTotalExVat * vatRate) + shippingAmount * vatRate;
    var total = basketTotalExVat + shippingAmount + vat;
    $('#shipping-amount span').replaceWith('<span>£' + shippingAmount.toFixed(2) + '</span>');
    $('#vat-total span').replaceWith('<span>£' + vat.toFixed(2) + '</span>');
    $('#basket-total-inc-vat span').replaceWith('<span>£' + total.toFixed(2) + '</span>');
    var valuesToSubmit = 'shipping_class_id=' + $(this).val();
    $.ajax({
      url: '/basket/set_shipping_class',
      type: 'POST',
      data: valuesToSubmit
    }).done(function(data) {
    });
  });

  $('#address_delivery_country_id').change(function() {
    var valuesToSubmit = 'shipping_country_id=' + $(this).val();
    $.ajax({
      url: '/basket/set_shipping_country',
      type: 'POST',
      data: valuesToSubmit
    }).done(function(data) {
      window.location.href = '/basket';
    });
  });

  $('input[name="checkout"]').click(function() {
    if ($('.shipping-class').length > 0) {
      if ($('input[name="shipping_class_id"]:checked').length == 0) {
        window.alert('Please choose a delivery class.');
        return false;
      }
    }
  });
}

export { basket };
