function productSearchFilter() {
  $('#filter').keyup(function() { filter($(this)); });
  $('#filter').change(function() { filter($(this)); });

  function filter(input) {
    var filterText = input.val().toLowerCase();
    $('.product-name').each(function() {
      if ($(this).data('keywords').toLowerCase().indexOf(filterText) == -1) {
        $(this).closest('.product-panel').hide();
      } else {
        $(this).closest('.product-panel').show();
      }
    });
  }
}

function simpleSearchFilter() {
  $('#simple-filter').keyup(function() { filter($(this)); });
  $('#simple-filter').change(function() { filter($(this)); });

  function filter(input) {
    var filterText = input.val().toLowerCase();
    $('.filterable').each(function() {
      if ($(this).data('keywords').toLowerCase().indexOf(filterText) == -1) {
        $(this).hide();
      } else {
        $(this).show();
      }
    });
  }
}

export { productSearchFilter, simpleSearchFilter };
