function expander() {
  $('.expander').click(function() {
    var target = $($(this).attr('href'));
    var expand = $(this).data('expand') || '▶';
    var contract = $(this).data('contract') || '▼';
    if(target.is(':hidden')) {
      $(this).html(contract);
    } else {
      $(this).html(expand);
    }
    target.toggle();
    return false;
  });

  $('.expander').each(function() {
    if(!$(this).data('open')) {
      var target = $($(this).attr('href'));
      target.hide();
    }
  });
}

export { expander };
