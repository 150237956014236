function vat() {
  const vatControls = $('#vatControls').data('showUserVatControls');
  if (vatControls) {
    $('#show-ex-vat').click(function() {
      $('.inc-vat').hide();
      $('.ex-vat').show();
      $.cookie('vat', 'ex', {path: '/'});
      return false;
    });

    $('#show-inc-vat').click(function() {
      $('.ex-vat').hide();
      $('.inc-vat').show();
      $.cookie('vat', 'inc', {path: '/'});
      return false;
    });
  } else {
    $('#show-ex-vat').hide();
    $('#show-inc-vat').hide();
  }
  applyVatStyle();
}

function applyVatStyle() {
  const vatControls = $('#vatControls').data('showUserVatControls');
  if (vatControls) {
    if($.cookie('vat') == 'inc') {
      $('.ex-vat').hide();
    } else if($.cookie('vat') == 'ex') {
      $('.inc-vat').hide();
    } else {
      applyDefaultVatStyle();
    }
  } else {
    applyDefaultVatStyle();
  }
}

function applyDefaultVatStyle() {
  const showVatPrices = $('#vatControls').data('showVatPrices');
  if (showVatPrices === 'inclusive') {
    $('.ex-vat').hide();
  } else if (showVatPrices === 'exclusive') {
    $('.inc-vat').hide();
  } else if (showVatPrices === 'both') {
    $('body').addClass('vat-both');
  }
}

export { vat };
