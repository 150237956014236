const step = (quantityElement, delta) => {
  const value = parseInt(quantityElement.value, 10);
  if (Number.isNaN(value)) {
    return;
  }
  quantityElement.value = Math.max(0, Math.min(999, value + delta));
};

const increment = () => {
  const increments = document.querySelectorAll('.increment');

  // Ensure an array is returned for use of .forEach
  Array.from(increments).forEach((element) => {
    const id = element.dataset.itemId;
    const quantityElement = document.querySelector(`#item-${id}-quantity`);
    element.addEventListener('click', () => {
      step(quantityElement, 1);
    });
  });
};


const decrement = () => {
  const decrements = document.querySelectorAll('.decrement')

  // Ensure an array is returned for use of .forEach
  Array.from(decrements).forEach((element) => {
    const id = element.dataset.itemId;
    const quantityElement = document.querySelector(`#item-${id}-quantity`);
    element.addEventListener('click', () => {
      step(quantityElement, -1);
    });
  });
};

export { decrement, increment }
