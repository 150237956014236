const burgerMenu = () => {
  const burger = document.querySelector('.mobile-menu-burger');
  const topLevelMenu = document.querySelector('.links');

  const toggleVisibility = (elm, display = 'block') => {
    switch(elm.style.display) {
      case 'none':
        elm.style.display = display;
        break;
      case display:
        elm.style.display = 'none';
        break;
      default:
        elm.style.display = display;
    }
  }

  burger.addEventListener('click', () => {
    toggleVisibility(topLevelMenu, 'flex')
  });
}

export default burgerMenu;

