import '../shared/jquery.cookie';

function search() {
  const $simpleSearchSubmits = $('.search-submit input[type=submit]');
  let simpleSearchSubmit = null;
  $simpleSearchSubmits.on('click', () => {
    simpleSearchSubmit = this;
  });
  $('#simple-search').submit(function(event) {
    if (null === simpleSearchSubmit) {
      simpleSearchSubmit = $simpleSearchSubmits[0];
    }
    iosResetZoom();
    easySearch(event);
  });

  // Bryce Johnson
  // http://stackoverflow.com/questions/29927680/how-can-i-reset-the-zoom-after-form-submission-in-mobile-safari-while-maintaini
  function iosResetZoom() {
    if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)) {
      const viewportmeta = document.querySelector('meta[name="viewport"]');
      if (viewportmeta) {
        viewportmeta.setAttribute('content', 'minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0');
        viewportmeta.setAttribute('content', 'initial-scale=1.0');
      }
    }
  }

  function easySearch(event) {
    event.preventDefault();
    displayResults('<p>Searching...</p>');
    setTimeout(() => doPartSearch(), 50);

    return false;
  }

  function manufacturerSearch() {
    const mfr = $('#manufacturer').find(':selected').val();
    const app = $('#appliance').find(':selected').val();

    if (mfr && app) {
      const newLoc = '/appliances/' + mfr + '/' + app;
      if ('Key Parts' == $(simpleSearchSubmit).val()) {
        newLoc += '/key';
      }
      window.location = newLoc;
    }

    return false;
  }

  $('#manufacturer').change(function() {
    const mfr = $(this).find(':selected').val();
    const $appliance = $('#appliance');

    $appliance
      .prop('disabled', true)
      .find('option')
      .remove()
      .end()
      .append('<option>Choose Model</option>');

    if (mfr === '') {
      return
    }

    $.ajax({
      url: '/appliances/' + mfr.toLowerCase() + '.json',
      type: 'GET'
    }).done((data) => {
      const html = [];
      data.forEach((appliance) => {
        html.push('<option value="' + appliance.slug + '">' + appliance.name_with_gcn + '</option>')
      })
      $appliance.prop('disabled', false).append(html.join(''));
    }).fail(() => {
      alert('Could not fetch list of appliances. Please try again.');
    });
    return false;
  });

  $('#appliance').change(() => {
    manufacturerSearch();
  });

  function displayError(text) {
    $('#result').html('<p class="error">Error: ' + text + '</p>');
  }

  function displayResults(html) {
    const imgcode = '';
    const $result = $('#result');
    if (html == '') {
      $result.hide();

      if ($result.html() != imgcode) {
        $result.html(imgcode);
        $('body').removeClass('showing-results');
      }
    } else {
      $result.show();
      $result.html(html);
      $('body').addClass('showing-results');
    }
  }

  function doPartSearch() {
    const part = document.getElementById('SS').value;

    if (part.length < 1) {
      displayResults('');
      return;
    }

    $.ajax({
      url: "/products/search?query=" + part,
      cache: false
    }).done((html) => {
      displayResults(html);
      applyVatStyle();
    }).fail((jqXHR, textStatus) => {
      displayError(textStatus);
    });
  }

  window.displayResults = displayResults;

}

export { search };
