function navDropdown() {
  const dropdownButtons = document.querySelectorAll('.dropdown-button')

  let expandedMenu = null
  let expandedButton = null

  dropdownButtons.forEach(el => {
    const dropdownContent = document.getElementById(el.dataset.target)
    el.addEventListener('click', () => {
      if (expandedMenu) {
        expandedMenu.classList.remove('expanded')
        expandedButton.classList.remove('expanded')
        const lastExpandedMenu = expandedMenu
        expandedMenu = null
        if (lastExpandedMenu === dropdownContent) return
      }
      expandedMenu = dropdownContent
      expandedButton = el
      dropdownContent.classList.add('expanded')
      el.classList.add('expanded')
    })
  })

  document.addEventListener('click', (e) => {
    if (!expandedMenu || e.target.classList.contains("dropdown-button") || e.target.closest(".dropdown-content")) return
    expandedMenu.classList.remove('expanded')
    expandedButton.classList.remove('expanded')
    expandedMenu = null;
  })
}

export default navDropdown

