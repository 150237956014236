import '../shared/jquery.cookie';

if(!window.console) {
  console = {log: function() {}};
}

function shipping() {
  $('.shipping').each(function() {
    var options = $(this).data('options');
    var html = '';
    for(var c in uk_shipping_classes) {
      if (options.indexOf(c) != -1) {
        html = html + '<div class="shipping-option">' + stripShippingLetter(uk_shipping_classes[c].html) + '</div>';

        var pricingMessage = uk_shipping_classes[c].pricingMessage;
        if (pricingMessage) {
          displayShippingPricingMessage($(this), pricingMessage);
        }
      }
    }
    $(this).replaceWith(
      '<div class="shipping"><h2 class="h2">Delivery Options</h2>' + html + '</div>'
    );
  });
  if (typeof(applyVatStyle) != 'undefined') {
    applyVatStyle();
  }
}

function stripShippingLetter(str) {
  return str.substring(3);
}

// Replaces the contents of the closest .pricing-message with the given pricing
// message related to shipping, as long as the message is currently blank.
function displayShippingPricingMessage($shipping, pricingMessage) {
  var $pricingMessage = $shipping.closest('.product').find('.pricing-message');
  var msg = $.trim($pricingMessage.html());
  if (!msg) {
    $pricingMessage.html('<span>' + pricingMessage + '</span>');
  }
}

function autoSubmitPayByForm() {
  var $form = $('.pay-by form');
  if($form.length == 1) {
    $form.hide().after('<p>You are now being redirected...</p>').submit();
  }
}

function setupModalNotice() {
  const $modalNotice = $('#modal-notice');
  if($modalNotice.length > 0) {
    var cookieName = 'mn_' + $modalNotice.data('identifier');
    if($.cookie(cookieName) == undefined) {
      $.cookie(cookieName, '1', { expires: 7, path: '/' });
      $modalNotice.show();
      $('.close').click(function() {
        $modalNotice.hide();
      });
    }
  }
}

function googleEventTracking() {
  $('.click-track').on('click', function(event) {
    // No Analytics in dev.
    if (typeof(ga) == 'undefined') {
      return;
    }
    ga('send', 'event', {
      eventCategory: $(this).data('event-category'),
      eventAction: $(this).data('event-action'),
      eventLabel: $(this).data('event-label'),
      eventValue: $(this).data('event-value'),
      transport: 'beacon'
    });
  });
}

export { autoSubmitPayByForm, googleEventTracking, setupModalNotice, shipping };
