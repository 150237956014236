import { basket } from './basket';
import { checkout } from './checkout';
import { expander } from './expander';
import { productSearchFilter, simpleSearchFilter } from './filter';
import { autoSubmitPayByForm, googleEventTracking, setupModalNotice, shipping } from './main';
import { search } from './search';
import { spam } from './spam';
import { vat } from './vat';

function pageLoad() {
  checkout();
  shipping();
  vat();
  basket();
  expander();
  autoSubmitPayByForm();
  search();
  setupModalNotice();
  productSearchFilter();
  simpleSearchFilter();

  if (typeof(addressHelpers) != 'undefined') {
    addressHelpers();
  }
  if (typeof(partNumberDisplay) != 'undefined') {
    partNumberDisplay();
  }
  spam();
  googleEventTracking();
}

document.addEventListener("turbolinks:load", () => {
  pageLoad();
});
